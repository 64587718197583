import React from 'react';
import {
  Button,
  Stack,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
} from '@mui/material';

interface PlanDefinition {
  id: string;
  planName: string;
  monthlyRate: number;
  billingInterval: string;
  stripeProductId: string;
  stripePriceId: string;
  cardApplicationFeeRate: number;
  achApplicationFeeRate: number;
  tier: string;
  active: boolean;
}

interface PlanSet {
  id: string;
  name: string;
  planDefinitions: PlanDefinition[];
}

interface PlanSetsTableProps {
  planSets: PlanSet[];
}

interface PlanSetTableProps {
  planSet: PlanSet;
}

const PlanSetTable = ({ planSet }: PlanSetTableProps) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '350px' }}>Plan Name</TableCell>
            <TableCell>Billing Interval</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Card Fee Rate</TableCell>
            <TableCell>Stripe Price Id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {planSet.planDefinitions.map((planDefinition) => (
            <TableRow>
              <TableCell sx={{ width: '350px' }}>
                {planDefinition.planName}
              </TableCell>
              <TableCell>{planDefinition.billingInterval}</TableCell>
              <TableCell>
                {planDefinition.billingInterval === 'monthly'
                  ? `$${planDefinition.monthlyRate / 100}/month`
                  : `$${Math.ceil(
                      (planDefinition.monthlyRate / 100) * 12
                    )}/year`}
              </TableCell>
              <TableCell>
                {(planDefinition.cardApplicationFeeRate * 100).toFixed(2)}%
              </TableCell>
              <TableCell>
                <Link
                  target="_blank"
                  rel="noopener"
                  href={`https://dashboard.stripe.com/prices/${planDefinition.stripePriceId}`}
                >
                  {planDefinition.stripePriceId}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PlanSetsTable = ({ planSets }: PlanSetsTableProps) => {
  const [showPlanSets, setShowPlanSets] = React.useState(false);

  return (
    <Stack spacing={2} margin={2}>
      <Button color="primary" onClick={() => setShowPlanSets(!showPlanSets)}>
        {showPlanSets ? 'Hide plan sets' : 'Show plan sets'}
      </Button>
      {showPlanSets && (
        <Stack gap="24px" spacing={2}>
          {planSets.map((planSet) => (
            <Stack
              spacing={2}
              sx={{ padding: '32px', border: '1px solid black' }}
            >
              <Typography variant="subtitle1">
                {planSet.name} Plan Set Options
              </Typography>
              <PlanSetTable key={planSet.id} planSet={planSet} />
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
