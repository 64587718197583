import { ApolloConsumer } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import copyToClipboard from 'copy-to-clipboard';
import { Template } from 'devextreme-react/core/template';
import DataGrid, {
  Button as DataGridButton,
  Column,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import map from 'lodash/map';
import join from 'lodash/join';
import first from 'lodash/first';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { FullScreen, PVSecondaryButton } from '@pv/common/components';
import { dataGridQuery } from '@pv/common/graphql';
import { phonePrint } from '@pv/common/utils';

const UserTable = ({ apolloClient }) => {
  const dataGrid = useRef();
  const theme = useTheme();
  const [dataSource, setDataSource] = useState();
  const [totalCount, setTotalCount] = useState();

  const createStore = () =>
    new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let { skip, take, sort, filter: rawFilter } = loadOptions;

        const filter = [];
        rawFilter = rawFilter || [];
        for (let i = 0; i < rawFilter.length; i += 2) {
          const f = rawFilter[i];
          const logical = rawFilter[i + 1];
          filter.push({
            field: f[0],
            operator: f[1],
            operands: [f[2]],
            logical,
          });
        }

        const options = {
          skip,
          take,
          sort,
          filter,
        };
        const variables = { venueIds: [], className: 'User', options };
        const query = dataGridQuery;
        const {
          data: { dataGrid },
        } = await apolloClient.query({ query, variables });
        let { records, totalCount } = dataGrid;
        records = map(records, (r) => ({
          ...r,
          venues: join(
            map(r.venueMemberships, (vm) => vm.venue.name),
            ', '
          ),
          organization: first(r.organizationMemberships)?.organization?.name,
          createdAt: moment(r.createdAt).format('MM/DD/YY'),
        }));
        setTotalCount(totalCount);

        return {
          data: records,
          totalCount,
        };
      },
    });

  const resetData = () => {
    setDataSource(createStore());
  };

  useEffect(() => {
    resetData();
  }, []);

  const onClickCopyMagicLink = ({ row }) => {
    const { data: user } = row;
    copyToClipboard(user.magicLoginLink);
    enqueueSnackbar(`Copied magic link for ${user.email}`, { variant: 'info' });
  };

  const onClickLoginAsUser = ({ row }) => {
    const { data: user } = row;
    window.open(user.magicLoginLink, 'loginasuser');
  };

  const lastSeenAtRender = ({ data }) =>
    data.lastSeenAt && moment(data.lastSeenAt).format('MM/DD/YY h:mm:ss a');

  const phoneRender = ({ data }) => phonePrint(data.phone);
  const renderTitle = () => <Typography variant="h4">Users</Typography>;

  const onToolbarPreparing = (e) => {
    const { items } = e.toolbarOptions;
    items.push({
      location: 'after',
      template: 'exportButton',
    });
    items.unshift({
      location: 'before',
      template: 'title',
    });
  };

  const onClickExport = () => {
    dataGrid.current.instance.exportToExcel();
  };

  const renderExportButton = () => (
    <PVSecondaryButton
      label="Export"
      marginLeft={theme.spacing(2)}
      onClick={onClickExport}
    />
  );

  return (
    <FullScreen paddingBottom={theme.spacing(3)}>
      {({ height }) => (
        <div>
          <DataGrid
            export={{ fileName: 'users' }}
            style={{ height }}
            ref={dataGrid}
            dataSource={dataSource}
            remoteOperations
            hoverStateEnabled
            onToolbarPreparing={onToolbarPreparing}
          >
            <SearchPanel visible />
            <Template name="title" render={renderTitle} />
            <Template name="exportButton" render={renderExportButton} />

            <Column dataField="id" allowSearch />
            <Column dataField="organization" allowSearch />
            <Column dataField="venues" allowSearch />
            <Column dataField="firstName" allowSearch />
            <Column dataField="lastName" allowSearch />
            <Column dataField="email" allowSearch />
            <Column dataField="phone" allowSearch cellRender={phoneRender} />
            <Column dataField="productUpdateEmail" />
            <Column dataField="lastSeenAt" cellRender={lastSeenAtRender} />
            <Column dataField="createdAt" />
            <Column type="buttons">
              <DataGridButton
                text="Copy Magic Link"
                icon="link"
                hint="Copy Magic Link"
                onClick={onClickCopyMagicLink}
              />
            </Column>
            <Column type="buttons">
              <DataGridButton
                text="Login as User"
                icon="user"
                hint="Login as User"
                onClick={onClickLoginAsUser}
              />
            </Column>
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Paging pageSize={50} />
          </DataGrid>
        </div>
      )}
    </FullScreen>
  );
};

export default () => (
  <ApolloConsumer>
    {(apolloClient) => <UserTable apolloClient={apolloClient} />}
  </ApolloConsumer>
);
