import React from 'react';

import {
  Button,
  Box,
  Stack,
  useTheme,
} from '@mui/material';

export const UsersPanel = ({ organization }) => {
  const theme = useTheme();
  return (
    <Stack sx={{ margin: theme.spacing(2) }} spacing={2}>
      {organization.users.map( (u) => (
        <Box key={u.id}>
          <Button variant="contained" href={`/admin/users/${u.id}`}>
            {u.firstName} {u.lastName} ({u.email}) - {u.role}
          </Button>
        </Box>
      ))}
    </Stack>
  );
};
