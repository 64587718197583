import React, { useState } from 'react';
import {
  Button,
  Grid,
  useTheme,
} from '@mui/material';
import VenueDrawer from '../../../components/VenueDrawer';

export const VenuesPanel = ({ organization, afterConfirm }) => {
  const theme = useTheme();
  const [openCreateVenueModal, setOpenCreateVenueModal] = useState(false);

  const onClickAddVenue = () => {
    setOpenCreateVenueModal(true);
  };

  return (
    <div style={{ margin: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              color="secondary"
              onClick={onClickAddVenue}
            >
              Add Venue
            </Button>
          </div>
        </Grid>
        {organization.venues.map((v) => (
          <Grid item xs={12} key={v.id}>
            <Button variant="contained" href={`/admin/venues/${v.id}`}>
              {v.name}
            </Button>
          </Grid>
        ))}
      </Grid>
      <VenueDrawer
        refetchVenues={afterConfirm}
        organization={organization}
        open={openCreateVenueModal}
        onClose={() => setOpenCreateVenueModal(false)}
      />
    </div>
  );
};
