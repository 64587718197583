import { ApolloConsumer } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Template } from 'devextreme-react/core/template';
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import map from 'lodash/map';
import React, { useEffect, useRef, useState } from 'react';
import {
  FullScreen,
  PVPrimaryButton,
  PVSecondaryButton,
} from '@pv/common/components';
import { dataGridQuery } from '@pv/common/graphql';
import { useNavigate } from 'react-router-dom';
import { CollectionDrawer } from '../components/CollectionDrawer';

const CollectionsTableWithoutProvider = ({ apolloClient }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dataGrid = useRef();
  const [dataSource, setDataSource] = useState();
  const [totalCount, setTotalCount] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);

  const createStore = () =>
    new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let { skip, take, sort, filter: rawFilter } = loadOptions;

        const filter = [];
        rawFilter = rawFilter || [];
        for (let i = 0; i < rawFilter.length; i += 2) {
          const f = rawFilter[i];
          const logical = rawFilter[i + 1];
          filter.push({
            field: f[0],
            operator: f[1],
            operands: [f[2]],
            logical,
          });
        }

        const options = {
          skip,
          take,
          sort,
          filter,
        };
        const variables = { venueIds: [], className: 'Collection', options };
        const query = dataGridQuery;
        const {
          data: { dataGrid },
        } = await apolloClient.query({ query, variables });
        let { records, totalCount } = dataGrid;
        records = map(records, (r) => ({
          ...r,
        }));
        setTotalCount(totalCount);

        return {
          data: records,
          totalCount,
        };
      },
    });

  const resetData = () => {
    setDataSource(createStore());
  };

  useEffect(() => {
    resetData();
  }, []);

  const renderTitle = () => <Typography variant="h4">Collections</Typography>;

  const onToolbarPreparing = (e) => {
    const { items } = e.toolbarOptions;
    items.push({
      location: 'after',
      template: 'exportButton',
    });
    items.push({
      location: 'after',
      template: 'addButton',
    });
    items.unshift({
      location: 'before',
      template: 'title',
    });
  };

  const onClickExport = () => {
    dataGrid.current.instance.exportToExcel();
  };

  const renderAddButton = () => (
    <PVPrimaryButton
      label="Add Collection"
      marginLeft={theme.spacing(2)}
      onClick={() => setOpenDrawer(true)}
    />
  );

  const renderExportButton = () => (
    <PVSecondaryButton
      label="Export"
      marginLeft={theme.spacing(2)}
      onClick={onClickExport}
    />
  );

  const onRowClick = ({ data }) => {
    navigate(`/admin/collections/${data.id}`);
  };
  return (
    <FullScreen paddingBottom={theme.spacing(3)}>
      {({ height }) => (
        <div>
          <DataGrid
            onRowClick={onRowClick}
            export={{ fileName: 'collections' }}
            style={{ height }}
            ref={dataGrid}
            dataSource={dataSource}
            remoteOperations
            hoverStateEnabled
            onToolbarPreparing={onToolbarPreparing}
          >
            <SearchPanel visible />
            <Template name="title" render={renderTitle} />
            <Template name="exportButton" render={renderExportButton} />
            <Template name="addButton" render={renderAddButton} />

            <Column dataField="id" allowSearch />
            <Column dataField="name" allowSearch />
            <Column dataField="slug" allowSearch={false} />
            <Column dataField="createdAt" />
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Paging pageSize={50} />
          </DataGrid>
          <CollectionDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          />
        </div>
      )}
    </FullScreen>
  );
};

export const Collections = () => (
  <ApolloConsumer>
    {(apolloClient) => (
      <CollectionsTableWithoutProvider apolloClient={apolloClient} />
    )}
  </ApolloConsumer>
);
