import React from 'react';
import {
  Button,
  Chip,
  Typography,
} from '@mui/material';
import copyToClipboard from 'copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';
import LaunchIcon from '@material-ui/icons/Launch';
import first from 'lodash/first';
import { StripeCustomerChip } from './StripeCustomerChip';

export const Header = ({ organization }) => {
  const onClickLoginAsUser = ({ row }) => {
    const user = first(organization.users);
    window.open(user.magicLoginLink, 'loginasuser');
  };

  const onClickIdChip = () => {
    copyToClipboard(organization.id);
    enqueueSnackbar(`Copied ${organization.id} to clipboard`);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Chip
          clickable
          onClick={onClickIdChip}
          variant="outlined"
          label={`ID ${organization.id}`}
        />
        <Typography variant="h2" sx={{ margin: '16px' }}>
          Organization: {organization.name}
        </Typography>
        <StripeCustomerChip organization={organization} />
      </div>
      <Button onClick={onClickLoginAsUser}>
        <LaunchIcon />
      </Button>
    </div>
  );
};
