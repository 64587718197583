import React from 'react';
import { GreenChip, RedChip } from '../../Venue/components';
import { openInNewTab } from '../../utils';


export const StripeCustomerChip = ({ organization }) => {
  if (!organization.stripeCustomerId) {
    return <RedChip label="No Stripe Customer" />;
  }
  return (
    <GreenChip
      label={organization.stripeCustomerId}
      onClick={openInNewTab({
        url: `https://dashboard.stripe.com/customers/${organization.stripeCustomerId}`,
      })}
    />
  );
};
