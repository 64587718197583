import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useMutation } from '@pv/common/hooks';
import {
  Button,
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
} from '@mui/material';
import { Check, X, ArrowUpRight } from 'react-feather';

import { enqueueSnackbar } from 'notistack';
import { marketplaceHost as getMarketplaceHost } from '@pv/common/api';

const pvEnv = process.env.REACT_APP_PV_ENV;
const marketplaceHost = getMarketplaceHost(pvEnv || '');

const adminVenueMarketplaceQuery = gql`
  query adminVenueMarketplaceQuery($id: ID!) {
    adminVenue(id: $id) {
      id
      name
      slug
      description
      suspicious
      onActivePaidSubscription
      skipSpamCheck
      marketplaceRank
      profileBanner
      type
      address {
        id
        latitude
        longitude
      }
      image
      images {
        id
        url
      }
      menu {
        id
        menuItems {
          id
          image
        }
      }
      subscription {
        id
        status
        tier
      }
      spaces {
        id
        name
        description
        displayInDirectory
        images {
          id
          url
        }
      }
    }
  }
`;

const adminUpdateVenueListingMutation = gql`
  mutation adminUpdateVenueListingMutation($input: AdminUpdateVenueInput!) {
    adminUpdateVenue(input: $input) {
      adminVenue {
        id
        marketplaceRank
      }
    }
  }
`;

const GreenCheck = () => <Check color="green" />;
const RedX = () => <X color="red" />;

interface Props {
  venueId?: String;
}

export const MarketplacePanel = ({ venueId }: Props) => {
  const [venue, setVenue] = React.useState<any>(null);

  const { data, loading, error } = useQuery(adminVenueMarketplaceQuery, {
    variables: { id: venueId },
    skip: !venueId,
    onCompleted: (data) => {
      setVenue(data.adminVenue);
    },
  });

  useEffect(() => {
    if (data) {
      setVenue(data.adminVenue);
    }
  }, [data]);

  const MARKETPLACE_LISTABILITY_STATUSES = {
    removed: -2,
    not_ready: -1,
    pending_approval: 0,
    approved: 1,
  };

  const [updateVenueListing] = useMutation(adminUpdateVenueListingMutation, {
    onCompleted: (data) => {
      enqueueSnackbar('Venue updated', { variant: 'success' });
    },
    onSomeErrorsCompleted: (errors) => {
      enqueueSnackbar('Error encountered while updating venue', {
        variant: 'error',
      });
    },
  });

  const removeListing = () => {
    updateVenueListing({
      variables: {
        input: {
          id: venueId,
          marketplaceRank: MARKETPLACE_LISTABILITY_STATUSES.removed,
        },
      },
    });
  };

  const approveListing = () => {
    updateVenueListing({
      variables: {
        input: {
          id: venueId,
          marketplaceRank: MARKETPLACE_LISTABILITY_STATUSES.approved,
        },
      },
    });
  };

  const temporarilyRemoveListing = () => {
    updateVenueListing({
      variables: {
        input: {
          id: venueId,
          marketplaceRank: MARKETPLACE_LISTABILITY_STATUSES.not_ready,
        },
      },
    });
  };

  if (loading || !venue) {
    return null;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const marketplaceRank = venue.marketplaceRank;
  const listingStatusDescriptions = {
    [MARKETPLACE_LISTABILITY_STATUSES.removed]: 'Removed',
    [MARKETPLACE_LISTABILITY_STATUSES.not_ready]: 'Profile Incomplete',
    [MARKETPLACE_LISTABILITY_STATUSES.pending_approval]: 'Pending Approval',
    [MARKETPLACE_LISTABILITY_STATUSES.approved]: 'Approved',
  };
  let listingStatusDescription = listingStatusDescriptions[marketplaceRank];

  listingStatusDescription =
    listingStatusDescription || marketplaceRank.toString();

  const listableSpaces = venue.spaces.filter((space: any) => {
    return space.displayInDirectory && space.images.length && space.description;
  });

  return (
    <Stack spacing={2} sx={{ padding: '24px' }}>
      <Typography variant="h2">
        Marketplace Status: <em>{listingStatusDescription}</em>
      </Typography>

      <Stack direction="row" gap={'10px'}>
        <Button
          href={`${marketplaceHost}/venues/${venue.slug}`}
          target="_blank"
          variant="outlined"
          color="primary"
          endIcon={<ArrowUpRight />}
        >
          View Venue Profile
        </Button>

        {(marketplaceRank >= MARKETPLACE_LISTABILITY_STATUSES.approved ||
          marketplaceRank <= MARKETPLACE_LISTABILITY_STATUSES.removed) && (
          <Button
            variant="contained"
            color="warning"
            onClick={temporarilyRemoveListing}
          >
            Hide Temporarily
          </Button>
        )}

        {marketplaceRank >= MARKETPLACE_LISTABILITY_STATUSES.removed && (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<X></X>}
            onClick={removeListing}
          >
            Remove
          </Button>
        )}
        {marketplaceRank < MARKETPLACE_LISTABILITY_STATUSES.approved && (
          <Button
            variant="contained"
            color="success"
            startIcon={<Check />}
            onClick={approveListing}
          >
            Approve
          </Button>
        )}
      </Stack>

      <TableContainer>
        <Table sx={{ width: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Profile Requirement</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Venue Type</Typography>
              </TableCell>
              <TableCell>
                {venue.type !== 'None' ? <GreenCheck /> : <RedX />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Profile Banner Image</Typography>
              </TableCell>
              <TableCell>
                {venue.profileBanner ? <GreenCheck /> : <RedX />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Venue Profile Images</Typography>
              </TableCell>
              <TableCell>
                {venue.images[0] ? <GreenCheck /> : <RedX />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Venue Description</Typography>
              </TableCell>
              <TableCell>
                {venue.description ? <GreenCheck /> : <RedX />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Has a logo</Typography>
              </TableCell>
              <TableCell>{venue.image ? <GreenCheck /> : <RedX />}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Mappable address</Typography>
              </TableCell>
              <TableCell>
                {venue.address?.latitude && venue.address?.longitude ? (
                  <GreenCheck />
                ) : (
                  <RedX />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Active Paid Subscription</Typography>
              </TableCell>
              <TableCell>
                {venue.onActivePaidSubscription ? <GreenCheck /> : <RedX />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Not a spammer</Typography>
              </TableCell>
              <TableCell>
                {venue.skipSpamCheck || !venue.suspicious ? (
                  <GreenCheck />
                ) : (
                  <RedX />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  Number of listable spaces with images and descriptions
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{listableSpaces.length}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Number of menu items with images</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {venue.menu.menuItems.filter((mi: any) => !!mi.image)
                    .length || 0}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
